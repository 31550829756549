:root {
  --primary-color: 4, 66, 14;
  --second-color:239, 238, 227;
  --third-color: 130, 132, 86;
  --fourth-color: 0, 116, 153;
  --fifth-color: 255, 255, 255;
}

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  min-width: 300px;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--second-color));
  transition: all 0.3s ease-in-out;
}
body::-webkit-scrollbar{
  display: auto;
  width: 7.5px;
}

body::-webkit-scrollbar-thumb{
  border-radius: 2rem;
  background-color: rgb(var(--primary-color));
}

body::-webkit-scrollbar-track{
  background-color:rgb(var(--second-color));
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(var(--primary-color));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

p{
  font-size: clamp(15px, 2vw, 20px);
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
