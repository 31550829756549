.contact{
    padding: 50px 0px 100px;
    width: 90%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    z-index: 2;
}
.contact-inner{
    width: 100%;
    height: 80%;
    max-width: 1250px;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.contact-inner-title{
    margin: 0 0 0 20%;
    text-align: left;
    font-size: clamp(15px, 2vw, 20px);
    font-weight: 800;
    width: 100%;
    color: rgb(var(--third-color));
}

.contact-inner-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: rgb(var(--third-color));
}

.contact-inner-box h1{
    color: rgb(var(--fourth-color));
}

.contact-inner-box p{
    font-size: clamp(12px, 2vw, 16px);
}

.contact-inner-box .colors{
    align-items: center;
    justify-content: center;
    width: 130%;
    margin-bottom: 5%;
}

.mailicons, .mailicons-hovered{
    width: 20%;
    height: 100%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mailicons .mailsvg{
    opacity: 1;
    width: 100%;
    transition: all 0.3s ease-in-out;
    fill: rgb(var(--fourth-color));
    cursor: pointer;
}

.mailicons .mailsendsvg{
    opacity: 0;
    width: 100%;
    margin-top: -120%;
    transition: all 0.3s ease-in-out;
    fill: rgb(var(--fourth-color));
    cursor: pointer;
}


.mailicons-hovered .mailsvg{
    opacity: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
    fill: rgb(var(--fourth-color));
    cursor: pointer;
}
.mailicons-hovered .mailsendsvg{
    opacity: 1;
    width: 100%;
    margin-top: -120%;
    transition: all 0.3s ease-in-out;
    fill: rgb(var(--fourth-color));
    cursor: pointer;
}


