.side-nav{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    background-color: rgb(var(--third-color));
    position: fixed;
    right:0;
    z-index: 100;
    transition: all 0.3s ease-in-out;
    transform: translateX(100%);
}

.side-nav-inner{
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.side-nav-inner-box{
    cursor: pointer;
    margin: 10px;
    font-size: clamp(20px, 2vw, 25px);
    color: rgb(var(--fourth-color));
    font-weight: 500;
}

.side-nav-inner-box .highlight{
    color: rgb(var(--primary-color));
}

.side-nav-filter{
    opacity: 0;
    pointer-events: none;
    height: 200vh;
    width: 100%;
    backdrop-filter: blur(5px);
    background-color: rgb(var(--primary-color),0.4);
    position: fixed;
    left:0;
    z-index: 99;
    transition: all 0.3s ease-in-out;
}

.side-nav-filter.checked{
    pointer-events: all;
    opacity: 1;
}
.side-nav.checked{
    transform: translateX(0%);
}



@media (min-width: 900px) {
    .hamburger{
      display: none;
    }
  }

@media (max-width: 900px) {
    .header-box2 {
      display: none;
    }
  }