.hero{
    padding: 320px 0px 150px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    z-index: 2;

}

.hero-inner{
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 1250px;
    min-height: 780px;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.hero-box2-inner{
    height: 10%;
    width: 100%;
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: end;
    transition: all 0.3s ease-in-out;
}

.hero-box2-inner .colors{
    
    display: flex;
    justify-content: end;
    width: 110%;
    align-items: center;
}

.hero-box2-inner .title{
    margin-right: 3%;
    text-align: end;
    width: 80%;
    font-size: clamp(15px, 2vw, 35px);
    color: rgb(var(--third-color));
    transition: all 0.3s ease-in-out;
}

.hero-box2-inner .title .highlight{
    color: rgb(var(--primary-color));
    transition: all 0.3s ease-in-out;
}

.hero-box2-inner .text {
    margin: -5% 3% 0 0;
    text-align: end;
    width: 100%;
    font-size: clamp(15px, 2vw, 20px);
    font-weight: bold;
    color: rgb(var(--third-color));
    transition: all 0.3s ease-in-out;
}
.hero-box2-inner .tech{
    
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}
.hero-box2-inner .tech ul{
    margin-right: 10%;
    text-align: right;
    direction: rtl;
    list-style: none;
}

.hero-box2-inner .tech ul li{
    display: flex;
    color: rgb(var(--fourth-color));
}

.bullet-svg {
    width: 15px; /* Adjust as needed */
    height: 20px; /* Adjust as needed */
    margin: 1px 0 0 10px; /* Creates some space between the SVG and the text */
    fill: rgb(var(--primary-color));
}


hr {
    height: 1px;
    width: 90%;
    background: rgb(var(--primary-color)); /* this will change the line color */
    margin-right: 0;
  }

.highlight2{
    color: rgb(var(--fourth-color));
    cursor: pointer;
    background-image: linear-gradient(rgb(var(--fourth-color)) 0 0);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s; /*change after the size immediately*/
}

.highlight2:hover{
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 2px;
}



.hero-box1{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    order: 1;
}

.hero-box2{
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    order: 2;
}

.hero-circle {
    margin: 0 20% 0 0;
    z-index: 0;
    width: 80%;
    border-radius: 50%;
    backdrop-filter: blur(10px);
    position: relative;
}

.hero-circle::after{
    --border-width: 8px; /* Adjust to the desired border width */
    content: "";
    position: absolute;
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    border-radius: 50%;
    z-index: -1;
    background: linear-gradient(
    60deg,
    rgb(var(--primary-color)),
    rgb(var(--second-color)),
    rgb(var(--third-color)),
    rgb(var(--fourth-color)),
    rgb(var(--fifth-color)),
    rgb(var(--primary-color)),
    rgb(var(--second-color)),
    rgb(var(--third-color)),
    rgb(var(--fourth-color)),
    rgb(var(--fifth-color))
);
    background-size: 300% 300%;
    background-position: 0 50%;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    animation: moveGradient 8s  infinite;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}


.hero-circle:hover::after{
    --border-width: 0px; /* Adjust to the desired border width */
    background: transparent;
    transition: all 0.3s ease-in-out;
    border: transparent;
    box-shadow:
    -10px -3px 0 15px rgb(var(--second-color)),
    -15px -8px 0 30px transparent,
    -20px -13px 0 35px rgb(var(--primary-color)),
    -25px -18px 0 40px transparent,
    -30px -23px 0 50px rgb(var(--third-color)),
    -35px -28px 0 60px transparent,
    -40px -33px 0 70px rgb(var(--fourth-color));
}


.hero-circle img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

/* animation */
@keyframes fadeInFromBottom {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeIn {
    animation: fadeInFromBottom 2s ease-out forwards;
  }
  

  @media (max-width: 600px){
    .hero{
        padding: 150px 0px 100px;
    }
    .hero-inner{
        flex-direction: column;
    }
    .hero-box1{
        margin-top: 30px;
        order: 2;
        width: 90%;
    }

    .hero-box2{
        order: 1;
        width: 90%;
        
    }
    .hero-circle{
       margin: 0
    }
    .hero-box2-inner .colors{
        margin-right: 0;    
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
    .hero-box2-inner .title, .hero-box2-inner .text, .hero-box2-inner .tech ul{
        margin-right: 8%;
        text-align: left;
        width: 90%;
    }

    .hero-box2-inner .tech ul{
        direction: ltr;
    }

    hr{
        margin-left: 0;
    }

 

}