.resume{
    padding: 150px 0px 150px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    z-index: 2;
}

.resume-inner{
    width: 100%;
    height: 80%;
    max-width: 1250px;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    
}
.resume-inner-title{
    margin: 0 0 3% 20%;
    text-align: left;
    font-size: clamp(15px, 2vw, 20px);
    font-weight: 800;
    width: 100%;
    color: rgb(var(--third-color));
}

.resume-inner-title .highlight{
    color: rgb(var(--primary-color));
}

.resume-box1,
.resume-box2{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.resume-box2{
    margin-top: -50px
}


.resume-box1-title{
    display: flex;
    text-align: left;
    margin: 0 0 3% 10%;
    align-items: center;
    width: 100%;
    height: 15%;
}

.resume-box1-selector{
    display: flex;
    margin-left: 10%;
    height: 10%;
    width: 100%;
}

.resume-box1-inner{
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.resume-box1-inner-title{
    font-size: clamp(10px, 2vw, 15px);
    margin-left: 10%;
    text-align: left;
}

.resume-box1-content-active h1{
    font-size: clamp(25px, 2vw, 30px);
    width: 80%;
    color: rgb(var(--primary-color));
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

.resume-box1-title h1{
    font-size: clamp(30px, 2vw, 35px);
    width: 80%;
    color: rgb(var(--primary-color));
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}


.resume-box1-inner .highlight1{
    color: rgb(var(--fourth-color));
}

.resume-box1-content, .resume-box1-inner-content{
    display: none;
}



.resume-box1-content-active{
    opacity: 1;
    transition:  all 0.5 ease-in-out;   
    animation: fadeIn 0.5s ease-in-out;
    color: rgb(var(--third-color));
}



.resume-box1-inner-content-active{
    opacity: 1;
    transition:  all 0.5 ease-in-out;   
    animation: fadeIn 0.5s ease-in-out;
    margin-left: 10%;
    text-align: left;
    width: 75%;
    height: 30%;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: rgb(var(--third-color));
}

.resume-box1-inner-content-active::-webkit-scrollbar, .resume-box1-inner-content::-webkit-scrollbar {
    display: auto;
    width: 5px;
    border-radius: 2rem;
    background-color: rgb(var(--primary-color));
  }


dd {
    display: list-item;
    list-style-type: "🤯";
    line-height: 150%;
    font-size: clamp(15px, 2vw, 16px);
    font-weight: bold;
    padding-inline-start: 1ch;
    transition: all 0.3s ease-in-out;
    background-color: transparent;
    background-repeat: no-repeat;
    padding-right: 5%;
  }

  dd {
    display: list-item;
    list-style-type: "🤯";
    line-height: 150%;
    font-size: clamp(15px, 2vw, 16px);
    font-weight: bold;
    padding-inline-start: 1ch;
    transition: all 0.3s ease-in-out;
    background-color: transparent;
    background-repeat: no-repeat;
    padding-right: 5%;
  }

dd:hover{
    color: rgb(var(--fourth-color));
}


@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  
  @keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
  }
  
  @media (max-width: 600px){
    .resume{
        padding: 100px 0px 50px;
    }
}


  @media (max-width: 500px){
    
.resume-box1-inner-content-active{
    margin-left: 0;
    width: 100%;
}
  }


