.selector{
    width: 90%;
    height: 100%;
    max-height: 75px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}

 .selector-inner{
    width: 100%;
    height: 90%;
    display: flex;
    align-items: end;
    color: rgb(var(--third-color))
}

.selector-box{
    text-decoration: none;
    min-width: 150px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: clamp(16px, 2vw, 20px);
    
}

.selector-box-active {
    background-color: rgba(var(--fourth-color), 0.3);
    color: rgb(var(--primary-color));
}

.selector-box:hover{
    background-color: rgba(var(--fourth-color), 0.3);
}

.selector-bar-wrapper{
    position: relative;
    width: 100%;
    height: 10px;
}

.selection-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 150px;
    background-color: rgb(var(--fourth-color));
    bottom: 0;
    border-radius: 2rem;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
}

.selection-bar-background{
    position: relative;
    background-color: rgba(var(--fourth-color), 0.3);
    height: 3px;
    z-index: 0;
}

.selector::-webkit-scrollbar-track
{
  background-color: var(--second-color);
}

.selector::-webkit-scrollbar
{
  height: 3px;
  width: 10px;
  background-color: var(--second-color);
}
.selector::-webkit-scrollbar-corner {
    background-color: var(--second-color); 
}

.selector::-webkit-scrollbar-thumb
{
  border-radius: 50px;
  width: 10px;
  background-color: var(--third-color);

}

@media (max-width: 768px) {
    .selector-box{
        min-width: 100px;
    }
    .selector-bar-wrapper{
        width: 100%;
    }
    .selection-bar{
        width: 100px;
    }
}