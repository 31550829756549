.header.hidden, .hamburger.hidden{
    transform: translateY(-150%);
}

.header{
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    box-shadow: 0px 4px 4px 2px rgba(13, 13, 13, 0.49);
    background-color: rgb(var(--second-color));
    width: 100%;
    height: 80px;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}


.header-inner{
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
}
.header-box1 { 
    display: flex;
    width: 50%;
}

.header-box2 {
    display: flex;
    justify-content: center;
    width: 75%;
    align-items: center;
}


.header-box1-logo{
    position: absolute;
    top: 12px;
    width: 50px;  
    height: 50px;
    z-index: 101;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.header-box1-circle{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    box-shadow:  -2px 1.5px 0 4px rgb(var(--primary-color),0.8);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.header-box1-circle.hovered{
    box-shadow:  -2px 1.5px 0 4px rgb(var(--primary-color),0.8);
    background-color: rgb(var(--third-color), 0.3);
}
.header-box1-circle.clicked{
    transform: translate(-4px, 4px);
    box-shadow:  0 0 0 2px rgb(var(--primary-color),0.8);
    background-color: rgb(var(--third-color), 0.3);
}

.header-box1-initials{
    position: absolute;
    transition: all 0.3s ease-in-out;
    margin-left: 10px;
    z-index: 6;
    color: rgb(var(--primary-color),0.8);
    font-size: 20px;
    font-weight: 500;
    top: 0;
}

.header-box1-initials.clicked{
    transition: all 0.3s ease-in-out;
    transform: translate(-4px, 4px);
}

.header-box2-inner{
    width: 100%;
    margin-right: -10%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: rgb(var(--fourth-color));
    cursor: pointer;
}

.header-box2-inner .highlight{
    color: rgb(var(--primary-color));
    transition: all 0.3s ease-in-out;
}

.header-box2-inner .highlight:hover{
    color: rgb(var(--fourth-color));
}

.resume-button{
    color: rgb(var(--primary-color));
    background-color: rgba(var(--second-color), 0.3);
    border: 2px solid rgb(var(--primary-color));
    border-radius: 2rem;
    box-shadow: rgb(var(--primary-color)) -4px 4px 0 0;
    cursor: pointer;
    display: inline-block;
    padding: 0 20px;
    line-height: 35px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;  /* Vertically align text */
    justify-content: center; /* Horizontally align text */
    transition: all 0.2s ease-in-out;

}

.header-box2-inner .resume-button{
    margin-left: 15px;
}

.resume-button:hover{
    border: 2px solid rgb(var(--fourth-color));
    box-shadow: rgb(var(--fourth-color)) -4px 4px 0 0;
    color: rgb(var(--fourth-color));
}

.resume-button:active {
    box-shadow: rgb(var(--primary-color)) 0px 0px 0 0;
    transform: translate(-4px, 4px);
}

.side-nav-inner .resume-button{
    margin-top: 10%;
    background-color: transparent;
    font-size: clamp(20px, 2vw, 25px);
    line-height: 35px;
    margin-top: 10%;
    height: 8%;
}


.hamburger{
    display: flex;
    transform: translateY(0);
    flex-direction: column;
    position: fixed;
    width: 100%;
    align-items: end;
    right: 5%;
    top: 3%;
    height: 10%;
    z-index: 104;
    transition: all 0.3s ease-in-out;
}
.hamburger.hidden.checked{
    transform: translateY(0%);
}

.hamburger div {
    width: 25px;
    height: 4px;
    border-radius: 3px;
    background-color: rgb(var(--primary-color));
    margin-top: 5px;
    transition: all 0.3s ease-in-out;
  }


.hamburger.checked .top-bun {
    transform: rotate(-45deg) translate(-7px, 6px) ;
    
}

.hamburger.checked .bottom-bun {
    opacity: 0;
    transform: rotate(45deg);
}

.hamburger.checked .meat {
    transform: rotate(45deg) ;

}

.hamburger.checked + .nav {
    top: 0;
    transform: scale(1);
}
