.colors{
    display: flex;
    width: 100%;
    height: 30%;
    align-items: center;
}


.color1, .color2, .color3, .color4{
    cursor: pointer;
    margin: 0 2% 0 2%;
    width: 5%;
    height: 12%;
    min-width: 50px;
    min-height: 15px;
    border-radius: 2rem;
    box-shadow: -1.5px 1.5px 0px 1.5px rgba(0, 0, 0, 0.374);
    transition: all 0.3s ease-in-out;
}

.color1:hover, .color2:hover, .color3:hover, .color4:hover{
    box-shadow: -1px 1px 0px 1px rgba(0, 0, 0, 0.374);
    transform: translate(-2px,2px);
}

.color1{
    background-color: rgb(239, 238, 227);

}

.color2{
    background-color: rgb(4, 66, 14);
}

.color3{
    background-color: rgb(130, 132, 86);
}

.color4{
    background-color: rgb(0, 116, 153);
}

@media (max-width: 600px){

    .colors{
        margin-bottom: 20px;
    }
    .color1, .color2, .color3, .color4{
        min-height: 25px;
        min-width: 25px;
        width: 6%;
        
    }
    .color2, .color3, .color4{
        margin-left: 20px;
    }


}