.load-wrapper {
    position: fixed;
    z-index: 1000;
    background-color: rgb(var(--second-color));
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.load-wrapper-completed{
    opacity: 0;
    visibility: hidden;
}

.loading-text {
    opacity: 0;
    background: 50% 100% / 50% 50% no-repeat radial-gradient(ellipse at bottom, rgb(var(--primary-color)), transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 500;
    font-size: clamp(100px, 5vw, 125px);
    font-family: "Source Sans Pro", sans-serif;
    animation: reveal 750ms ease-in-out forwards 700ms, glow 700ms ease-in-out 750ms, opacity 0s ease-in-out 500ms forwards; /* Add opacity animation */
    position: relative;
    z-index: 1; 
}

.circle {
    position: absolute;
    overflow: hidden;
    width: 240px;  
    height: 240px;
    cursor: none;
}

@keyframes reveal {
    0% {
      opacity: 0; 
    } 
    50% { 
      opacity: 1; 
    }
    80%{
        letter-spacing: 8px;
        opacity: 1; 
    }
    100% {
        background-size: 300% 300%;
        opacity: 1; 
    }
}

@keyframes glow {
  
    40% {
        text-shadow: 0 0 8px rgb(var(--primary-color));
    }
}
