.home{
   
    height: 100vh;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    z-index: 2;

}

.home-inner {
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 1250px;
    align-items: center;
    justify-content: center;
}
    
.home-box1 { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 50%;
    width: 60%;
    color: rgb(var(--third-color));
    order: 1;
    transition: all 0.3s ease-in-out;
}
.home-box2 { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 50%;
    width: 35%;
    min-width: 150px;
    margin-right: 5px;
    order: 2;
}

.home-box1-greet{
    height: 10%;
    width: 100%;
    margin-left: 15%;
    display: flex;
    font-size: clamp(16px, 2vw, 30px);
    flex-direction: column;
    justify-content: end;
}



.home-box1-greet .text{
    display: flex;
    margin: 0 0 0 2%;
}

.home-box1-name{
    width: 85%;
    display: flex;
    text-align: left;
    font-size: clamp(15px, 2vw, 35px);
}

.highlight{
    color: rgb(var(--primary-color));
    transition: all 0.3s ease-in-out;
}

.home-box1-desc{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
}

.home-box1-desc .text{
    margin-left: 3%;
    font-size: clamp(14px, 2vw, 25px);
}



.home-box2-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 80%;
    padding-bottom: 80%;  /* maintain aspect ratio */
    border-radius: 50%;  /* make it a circle */
    background-color: rgb(var(--second-color)); 
    box-shadow: inset 0px 6px 6px rgba(0, 0, 0, 0.3),
                2px 2px 2px 2px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
}

.home-box2 img{
    position: absolute;
    will-change: transform, filter;
    pointer-events: none;
    width: 27%;
    max-width: 385px;
    min-width: 150px;
    transition: all 0.3s ease-in-out;
    filter: none;
}

.home-box2.hovered .home-box2-circle{
    box-shadow: none;
}

.home-box2.hovered img{
    transform: scale(1.3) translate(15px, -15px);
    filter: drop-shadow(-6px 5px 0px rgb(var(--second-color), 0.8))
            drop-shadow(-6px 5px 0px rgb(var(--primary-color), 0.8))
            drop-shadow(-6px 5px 0px rgb(var(--third-color), 0.8))
            drop-shadow(-6px 5px 0px rgb(var(--fourth-color), 0.8));
            ;
    transition: all 0.3s ease-in-out;
}

.particles-container {
    height: 100px; 
  }

#tsparticles {   
    height: 100vh;
    position: relative;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    z-index: 0;
  }
  .home-box1-desc .buttons{
    display: flex;
    width: 120%;
    justify-content: space-between;
    margin: 3% 0 0 5%;
  }

  .home-box1-desc .about-button {
    width: 100%;
    min-width: 120px;
    max-width: 180px;
    height: 85%;
    max-height: 43px;
    position: relative;
    background-color: rgba(var(--second-color), 0.3);
    border: 2px solid rgb(var(--primary-color));
    border-radius: 30px;
    box-shadow: rgb(var(--primary-color)) -4px 4px 0 0;
    color: rgb(var(--primary-color));
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: clamp(12px, 2vw, 20px);
    padding: 0 18px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;  /* Vertically align text */
    justify-content: center; /* Horizontally align text */
    transition: all 0.2s ease-in-out;
}

.home-box1-desc .about-button:hover {
    color: rgb(var(--third-color));
    border: 2px solid rgb(var(--third-color));
    box-shadow: rgb(var(--third-color)) -4px 4px 0 0;
   
}

.home-box1-desc .about-button:active {
    box-shadow: rgb(var(--primary-color)) 0px 0px 0 0;
    transform: translate(-4px, 4px);
}

.intouch-button-wrapper{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-box1-desc .intouch-button{
    min-width: 80px;
    max-width: 180px;
    height: 80%;
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-size: clamp(12px, 2vw, 20px);
    color: rgb(var(--third-color));
    display: inline-block;
    padding-bottom:2px;
    background-image: linear-gradient(rgb(var(--primary-color)) 0 0);
    background-position: 0 80%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s; /*change after the size immediately*/
}

.home-box1-desc .intouch-button:hover{
    color: rgb(var(--primary-color));
    background-position: 100% 80%; /*OR bottom right*/
    background-size: 100% 2px;
}


.arrowsvg1,
.arrowsvg2,
.arrowsvg3{
    height: 45%;
    width: 6%;
    transition: all 0.3s ease-in-out;
    fill: rgb(var(--third-color));
}

.arrowsvg2 {
    opacity: 0;
    transform: translateX(-50%);
}

.arrowsvg3 {
    opacity: 0;
    transform: translateX(-100%);
}


.arrowsvg1-hover,
.arrowsvg2-hover,
.arrowsvg3-hover{
    height: 45%;
    width: 6%;
    transition: all 0.3s ease-in-out;
    fill: rgb(var(--primary-color));
}

.arrowsvg2-hover,
.arrowsvg3-hover{
    opacity: 1;
    transform: translateX(0%);
}



.home-box1-desc .button-wrapper{
    display: flex;
    width: 30%;
}

.home-box1-desc .button:hover {
    background-color: rgb(var(--second-color) );
}

.home-box1-desc .button:active  {
    box-shadow: black -2px 2px 0 0;
    transform: translate(-2px, 2px);
}


.home-box1-desc .button::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: black;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
}

.home-box1-desc .button:hover::after {
    opacity: 0.1; 
}

@media (max-width: 600px){
    .home-inner{
        flex-direction: column;
        min-height: 600px;
        max-height: 700px;
        
    }
    .home-box1{
        order: 2;
        width: 90%;
    }

    .home-box2{
        margin-top: 50px;
        order: 1;
        width: 50%;
    }
    .home-box2 img{
        width: 40%;
    }
    .home-box1-desc .buttons{
        width: 100%;
    }

}