.projects{
    padding: 50px 0px 100px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    z-index: 2;
}
.projects-inner{
    width: 100%;
    height: 80%;
    max-width: 1250px;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.projects-inner-title{
    margin: 0 0 -8% 20%;
    text-align: left;
    font-size: clamp(15px, 2vw, 20px);
    font-weight: 800;
    width: 100%;
    color: rgb(var(--third-color));
}

.projects-box1, .projects-box2 {
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.projects-box1-content, .projects-box2-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 60%;
    max-width: 550px;
    min-width: 450px;
    padding-left: 3%;
    z-index: 1;
    margin-right: -10%; /* adjust as necessary to create the desired overlap */
    text-align: left;
}

.projects-box1-content-title, .projects-box2-content-title{
    font-size: clamp(20px , 2vw, 25px);
    font-weight: 900;
    color: rgb(var(--fourth-color));
}

.projects-box1-content-sub, .projects-box2-content-sub{
    color: rgb(var(--primary-color));
    margin-top: -20px;
    font-size: clamp(15px , 2vw, 16px);
    font-weight: 600;
}

.projects-box1-content-desc, .projects-box2-content-desc{
    color: rgb(var(--third-color));

    font-size: clamp(10px , 2vw, 16px);
    font-weight: 300;
}


.projects-box2-content{
    text-align: right;
    padding-left: 0;
    margin-left: -10%;
    align-items: flex-end;
}

.projects-box1-content-desc, .projects-box2-content-desc{
    width: 80%;
    max-height: 220px;
    padding: 5%;
    overflow: scroll;
    border-radius: 2rem;
    background-color: rgb(var(--second-color));
    box-shadow: 
    2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.projects-box1-content-desc::-webkit-scrollbar,
.projects-box1-content-desc::-webkit-scrollbar-track,
.projects-box1-content-desc::-webkit-scrollbar-thumb,
.projects-box2-content-desc::-webkit-scrollbar,
.projects-box2-content-desc::-webkit-scrollbar-track,
.projects-box2-content-desc::-webkit-scrollbar-thumb{
    display: none;
}

.projects-box1-content-title,
.projects-box1-content-sub{
    margin-left: 5%;
}

.projects-box2-content-title,
.projects-box2-content-sub,
.projects-box2-content-footer{
    margin-right: 5%;
}

.projects-box1-content-footer, .projects-box2-content-footer{
    display: flex;
    width: 100%;
    margin: 3% 0 0 -5%;
    align-items: center;
    color: rgb(var(--fourth-color));
}

.projects-box2-content-footer{
    margin: 3% -5% 0 0;
}

.circle-image-container {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}

.circle-image-container img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    
}

.projects-box1-circle, .projects-box2-circle{
    position: relative;
    z-index: 0;
    width: 50%;
    max-width: 450px;
    max-height: 450px;
    overflow: hidden;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
}
.projects-box1-circle:hover, .projects-box2-circle:hover{
    z-index: 2;
}

.projects-box1-circle::after, .projects-box2-circle::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(var(--fourth-color), 0.8); 
    border-radius: inherit; 
    z-index: 0;
    transition: all 0.3s ease-in-out;
}

.projects-box1-circle:hover::after, .projects-box2-circle:hover::after {
    background: rgba(var(--fourth-color), 0); 
}

@media (max-width: 750px) {
    .projects-box1-content, .projects-box2-content {
        display: flex;
        padding: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
        max-width: 550px;
        min-width: 350px;
        margin: -10px 0 50px -40px;
        z-index: 1;
        color: rgb(var(--third-color));
        text-align: left;
        align-items: flex-start;
    }
    .projects-box1-content-title, .projects-box1-content-sub,
    .projects-box2-content-title, .projects-box2-content-sub{
        margin: 0 0 -5% 0;
        width: 90%;
    }

    .projects-box1-content-desc, .projects-box2-content-desc{
        background-color: transparent;
        padding: 3% 5% 5% 5%;
        color:rgb(var(--primary-color));
        font-weight: 400;
        font-size: 11px;
        box-shadow: none;
        text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.3);
    }

    .projects-box1-circle, .projects-box2-circle {
        position: absolute;
        transform: translateX(-55px);
        min-width: 300px;
        top: 10px;
        z-index: -1;
    }
    .projects-box1, .projects-box2 {
        position: relative;
        margin-left: 20%;
    }
    .projects-box1-circle::after, .projects-box2-circle::after{
        background: rgba(var(--second-color), 0.8); 
    }

    .projects-box1-content-footer, .projects-box2-content-footer{
        font-size: 12px;
        width: 90%;
        justify-content: center;
    }

}

.projects-box-content-tags{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.githubsvg{
    margin: 1% 0 0 0;
    width: 6%;
    transition: all 0.3s ease-in-out;
    stroke: rgb(var(--fourth-color));
    cursor: pointer;
}

.githubsvg:hover{
    stroke: rgb(var(--third-color));
   
}
@media (max-width: 600px){
    .projects{
        padding: 50px 0px 0px;
    }
}