.subprojects{
    height: 100vh;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    z-index: 2;
}
.subprojects-inner{
    width: 100%;
    height: 80%;
    max-width: 1250px;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
}

.subprojects-inner h1{
    color: rgb(var(--primary-color));
    margin-bottom: 5%;
}

.subprojects-grid{
    display: grid;
    padding: 10px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
    overflow: scroll;
    height: 50%;
    width: 100%;
    min-height: 550px;
    justify-items: center;
    overflow-x: hidden;
}

.subprojects-grid-item{
    display: flex;
    padding: 0 25px 0 25px;
    flex-direction: column;
    text-align: left;
    width: 70%;
    height: 300px;
    overflow: scroll;
    border: 2px solid rgb(var(--primary-color));
    border-radius: 1rem;
    color: rgb(var(--fourth-color));
    box-shadow: rgb(var(--primary-color)) -4px 4px 0 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

}

.grid-item-content{
    margin-top: -10px;
}

.subprojects-grid-item:hover{
    box-shadow: none;
    transform: translate(-4px, 5px);
    color: rgb(var(--primary-color));
}

.subprojects-grid-item .title{
    display: flex;
    height: 30px;
    margin: -5px 0 0 15px;
    align-items: center;
    justify-content: space-between;
}

.subprojects-grid-item::-webkit-scrollbar{
    display: none;
}

.subprojects-grid::-webkit-scrollbar{
    display: auto;
    width: 7.5px;
}

.subprojects-grid::-webkit-scrollbar-thumb{
    border-radius: 2rem;
    background-color: rgb(var(--primary-color));
}
.subprojects-grid-item h1{
    color:  rgb(var(--primary-color));
    font-weight: 500;
    font-size: clamp(25px, 2vw, 30px);
}

.subprojects-grid-item p{
    color:  rgb(var(--third-color));
    font-weight: 300;
    font-size: clamp(12px, 2vw, 16px);
    margin: 20px 0 0 30px;
}
.subprojects-grid-item h2{
    font-weight: 400;
    font-size: clamp(18px, 2vw, 20px);
}

.subprojects-grid-item-footer{
    display: flex;
    width: 100%;
    align-items: center;
    margin: 3% 0 0 -5%;
}

.subprojects-grid-item-tags{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    
}

.subprojects-grid-item-tags p{
    margin: 0;
    color:  rgb(var(--fourth-color));
}

.subprojects-grid-item .githubsvg{
    width: 30px;
    margin-right: 50px;
    stroke: rgb(var(--fourth-color));
}

.subprojects-grid-item .githubsvg:hover{
    stroke: rgb(var(--primary-color));
   
}

.subprojects-grid-item .closing-brackets{
    margin-top: 10px;
}

@media (max-width: 1200px) {
    .subprojects-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 800px) {
    .subprojects-grid{
        grid-template-columns: repeat(1, 1fr);
    }

    .subprojects-grid-item{
        width: 50%;
        height: 280px;
        overflow: hidden;
    }
}



@media (max-width: 500px) {
    .subprojects{
        padding:0;
    }
    .subprojects-grid-item{
        padding: 0 10px 0 10px;
        height: 245px;
        min-height: 220px;
        min-width: 190px;
        overflow: hidden;
    }
    .subprojects-grid-item .title{
        height: 20px;
        margin: 0 0 0 5px;
    }
    .subprojects-grid-item p{
        margin: 13px 0 0 10px;
    }
    .subprojects-grid-item-footer{
        margin: 0 0 0 -5%;
    }
    .subprojects-grid-item .closing-brackets{
        margin-top: 0;
    }
   
}
    
